* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

body {
  min-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}
